<script setup>
import { defineComponent, h } from 'vue'

const navigation = {
  social: [
    // {
    //   name: 'Facebook',
    //   href: '#',
    //   icon: defineComponent({
    //     render: () =>
    //       h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
    //         h('path', {
    //           'fill-rule': 'evenodd',
    //           'd': 'M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z',
    //           'clip-rule': 'evenodd',
    //         }),
    //       ]),
    //   }),
    // },
    // {
    //   name: 'Instagram',
    //   href: '#',
    //   icon: defineComponent({
    //     render: () =>
    //       h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
    //         h('path', {
    //           'fill-rule': 'evenodd',
    //           'd': 'M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z',
    //           'clip-rule': 'evenodd',
    //         }),
    //       ]),
    //   }),
    // },
    {
      name: 'X',
      href: 'https://x.com/PROJECT_Jcard',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
            h('path', {
              d: 'M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z',
            }),
          ]),
      }),
    },
    // {
    //   name: 'GitHub',
    //   href: '#',
    //   icon: defineComponent({
    //     render: () =>
    //       h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
    //         h('path', {
    //           'fill-rule': 'evenodd',
    //           'd': 'M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z',
    //           'clip-rule': 'evenodd',
    //         }),
    //       ]),
    //   }),
    // },
    // {
    //   name: 'YouTube',
    //   href: '#',
    //   icon: defineComponent({
    //     render: () =>
    //       h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
    //         h('path', {
    //           'fill-rule': 'evenodd',
    //           'd': 'M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z',
    //           'clip-rule': 'evenodd',
    //         }),
    //       ]),
    //   }),
    // },
    // telegram
    {
      name: 'Telegram',
      href: 'https://t.me/ProjectJOfficial',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 1024 1024' }, [
            h('path', {
              d: 'M679.424 746.861714l84.004571-395.995428c7.424-34.852571-12.580571-48.566857-35.437714-40.009143l-493.714286 190.281143c-33.718857 13.129143-33.133714 32-5.705142 40.557714l126.281142 39.424 293.156572-184.576c13.714286-9.142857 26.294857-3.986286 16.018286 5.156571l-237.129143 214.272-9.142857 130.304c13.129143 0 18.870857-5.705143 25.709714-12.580571l61.696-59.428571 128 94.281142c23.442286 13.129143 40.009143 6.290286 46.299428-21.723428zM1024 512c0 282.843429-229.156571 512-512 512S0 794.843429 0 512 229.156571 0 512 0s512 229.156571 512 512z',
            }),
          ]),
      }),
    },
  ],
}
</script>

<template>
  <footer class="relative bg-black text-left" aria-labelledby="footer-heading">
    <h2 id="footer-heading" class="sr-only">
      Footer
    </h2>
    <div class="mx-6 pb-8 lg:mx-8">
      <!-- <div class="hidden border-t border-white/10 pt-8 lg:flex lg:items-center lg:justify-between lg:pt-24 sm:pt-20">
        <div>
          <h3 class="text-sm text-white font-semibold leading-6">
            Subscribe to our newsletter
          </h3>
          <p class="mt-2 text-sm text-gray-300 leading-6">
            The latest news, articles, and resources, sent to your inbox weekly.
          </p>
        </div>
        <form class="mt-6 lg:mt-0 sm:max-w-md sm:flex">
          <label for="email-address" class="sr-only">Email address</label>
          <input id="email-address" type="email" name="email-address" autocomplete="email" required="" class="min-w-0 w-full appearance-none border-0 rounded-md bg-white/5 px-3 py-1.5 text-base text-white shadow-sm ring-1 ring-white/10 ring-inset sm:w-56 sm:text-sm placeholder:text-gray-500 sm:leading-6 focus:ring-2 focus:ring-indigo-500 focus:ring-inset" placeholder="Enter your email">
          <div class="mt-4 sm:ml-4 sm:mt-0 sm:flex-shrink-0">
            <button type="submit" class="w-full flex items-center justify-center rounded-md bg-purple-700 px-3 py-2 text-sm text-white font-semibold shadow-sm hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-indigo-500 focus-visible:outline-offset-2 focus-visible:outline">
              Subscribe
            </button>
          </div>
        </form>
      </div> -->
      <div class="border-t border-white/10 pt-8 md:flex md:items-center md:justify-between">
        <div class="flex md:order-2 space-x-6">
          <a v-for="item in navigation.social" :key="item.name" :href="item.href" target="_blank" class="text-gray-500 hover:text-gray-400">
            <span class="sr-only">{{ item.name }}</span>
            <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
          </a>
        </div>
        <p class="mt-8 text-xs leading-5 text-gray-400 md:order-1 md:mt-0">
          &copy; 2024 Project J. All rights reserved.
        </p>
      </div>
    </div>
  </footer>
</template>
