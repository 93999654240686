<script setup>
import {
  Dialog,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from '@headlessui/vue'
import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/vue/20/solid'
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  XMarkIcon,
} from '@heroicons/vue/24/outline'
import { ref } from 'vue'

const mobileMenuOpen = ref(false)

const isOpen = computed(() => {
  return (new Date()) > (new Date('2024-10-18 17:00 GMT+9'))
})
</script>

<template>
  <header class="fixed z-10 w-full bg-black/50">
    <nav class="mx-auto max-w-7xl flex items-center justify-between p-6 lg:px-8" aria-label="Global">
      <div class="flex lg:flex-1">
        <a href="/" class="p-1.5 -m-1.5">
          <span class="sr-only">Project J</span>
          <img class="h-10 w-auto" src="/images/logo.png" alt="">
        </a>
      </div>
      <div class="flex lg:hidden">
        <button type="button" class="inline-flex items-center justify-center rounded-md p-2.5 text-white -m-2.5" @click="mobileMenuOpen = true">
          <span class="sr-only">Open main menu</span>
          <Bars3Icon class="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <PopoverGroup class="hidden lg:flex lg:gap-x-12">
        <a href="/#farming" class="text-sm text-gray-900 font-semibold leading-6 shadow-red drop-shadow-lg md:text-white">Farming</a>
        <a href="/#roadmap" class="text-sm text-gray-900 font-semibold leading-6 md:text-white">Roadmap</a>
        <a href="/#team" class="text-sm text-gray-900 font-semibold leading-6 md:text-white">Team</a>
      </PopoverGroup>
      <div v-if="isOpen" class="hidden lg:flex lg:flex-1 lg:justify-end">
        <a href="/app" class="text-sm text-gray-900 font-semibold leading-6 md:text-white">Play Now <span aria-hidden="true">&rarr;</span></a>
      </div>
    </nav>
    <Dialog class="lg:hidden" :open="mobileMenuOpen" @close="mobileMenuOpen = false">
      <div class="fixed inset-0 z-10" />
      <DialogPanel class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
        <div class="flex items-center justify-between">
          <a href="#" class="p-1.5 -m-1.5">
            <span class="sr-only">Project J</span>
            <img class="h-10 w-auto invert" src="/images/logo.png" alt="">
          </a>
          <button type="button" class="rounded-md p-2.5 text-gray-700 -m-2.5" @click="mobileMenuOpen = false">
            <span class="sr-only">Close menu</span>
            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div class="mt-6 flow-root">
          <div class="-my-6 divide-y divide-gray-500/10">
            <div class="py-6 space-y-2">
              <a href="/#farming" class="block rounded-lg px-3 py-2 text-base text-gray-900 font-semibold leading-7 -mx-3 hover:bg-gray-50" @click="mobileMenuOpen = false">Farming</a>
              <a href="/#roadmap" class="block rounded-lg px-3 py-2 text-base text-gray-900 font-semibold leading-7 -mx-3 hover:bg-gray-50" @click="mobileMenuOpen = false">Roadmap</a>
              <a href="/#team" class="block rounded-lg px-3 py-2 text-base text-gray-900 font-semibold leading-7 -mx-3 hover:bg-gray-50" @click="mobileMenuOpen = false">Team</a>
            </div>
            <div v-if="isOpen" class="py-6">
              <a href="/app" class="block rounded-lg px-3 py-2.5 text-base text-gray-900 font-semibold leading-7 -mx-3 hover:bg-gray-50" @click="mobileMenuOpen = false">Play Now</a>
            </div>
          </div>
        </div>
      </DialogPanel>
    </Dialog>
  </header>
</template>
